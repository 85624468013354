<template>
    <form @submit.prevent="submit">
        <div class="text-center mb-8 font-bold">
            <span class="block text-sm uppercase">{{ lineFirst }}</span>
            <button class="text-sm text-indigo-400" @click.prevent="$emit('change-number')">змінити номер</button>
            <h3 class="text-xl mt-2">{{ lineSecond }}</h3>
        </div>

        <div class="mb-5">
            <VOtpInput
                class="flex flex-row justify-center text-center space-x-2"
                input-classes="w-full py-2 px-3 sm:py-3 sm:px-6 h-12 text-sm font-bold placeholder-gray-400 border-2 border-black focus:outline-indigo border text-center form-control"
                :conditionalClass="['opt-input-one', 'two', 'three', 'four']"
                separator=""
                inputType="tel"
                :num-inputs="4"
                v-model:value="form.verification_code"
                :should-auto-focus="true"
                @on-change="form.verification_code = $event"
                @on-complete="submit"
            />

            <InputError class="mt-2 text-center" :message="form.errors.verification_code"/>
        </div>

        <PrimaryButton
            :disabled="form.verification_code.length < 4"
            :loading="form.processing"
        >
            Відправити
        </PrimaryButton>

        <div class="mt-5 text-center font-bold">
            <p v-if="!form.via_sms && channel === 'phone'">Не надійшов дзвінок?</p>
            <div class="flex justify-center items-center">
                <Loader v-if="sendSmsForm.processing" class="h-5 w-5 mr-2 text-indigo-600"/>
                <button
                    :disabled="timer || sendSmsForm.processing"
                    @click.prevent="sendSms"
                    :class="timer ? 'text-gray-500' : 'cursor-pointer text-indigo-500'"
                >
                    Відправити SMS {{ form.via_sms ? 'повторно' : '' }} {{ timer ? `через ${timer}` : '' }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import InputError from "@/Components/InputError.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {useApiForm} from "@/Helpers/useApiForm/useApiForm";
import Loader from "@/Components/Svg/Loader.vue";
import VOtpInput from "vue3-otp-input";

export default {
    components: {Loader, PrimaryButton, InputError, VOtpInput},
    props: {
        form: Object,
        submit: Function,
        channel: String,
    },
    data() {
        return {
            waitTime: 90,
            seconds: null,
            timer: '',
            sendSmsForm: useApiForm({
                'phone': this.form.phone
            })
        }
    },
    computed: {
        lineFirst () {
            return this.form.via_sms || this.channel === 'telegram'
                ? `Ми надіслали код на номер ${this.form.phone}`
                : `Ми дзвонимо на номер ${this.form.phone}`
        },
        lineSecond () {
            return this.form.via_sms || this.channel === 'telegram'
                ? `Введи код з ${this.form.via_sms ? '' : 'Telegram або'} SMS`
                : `Введи останні 4 цифри номеру, що дзвонить`
        }
    },
    methods: {
        setTimer() {
            let seconds = this.seconds % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`
            }

            this.timer = `0${Math.floor(this.seconds / 60)}:${seconds}`

            this.seconds--
        },
        sendSms() {
            this.sendSmsForm.patch(this.route('login.send_sms'), {
                onSuccess: () => {
                    if (!this.form.via_sms) {
                        this.form.via_sms = true
                        this.form.clearErrors('verification_code')
                    }

                    this.startTimer()
                }
            })
        },
        startTimer() {
            this.seconds = this.waitTime

            this.setTimer()

            const interval = setInterval(() => {
                this.setTimer()

                if (this.seconds < 0) {
                    this.timer = null

                    clearInterval(interval)
                }
            }, 1000)
        }
    },
    mounted() {
        this.startTimer()
    }
}
</script>
